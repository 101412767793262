import { Link } from "@mui/material"
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { Button, Checkbox, Grid, Input, Layout, Loader, LoaderContainer, Paper, Profile, Table } from "../../components"
import { useAPI } from "../../hooks"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { enqueueSnackbar } from "notistack"
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useParams } from "react-router-dom"
import { useContext } from "react"
import { AuthContext } from "../../contexts"

export const ProfilePage = () => {
    const params = useParams()
    const { me } = useContext(AuthContext)

    const id = params.id || me?.id

    if (!id) {
        return (
            <div style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Loader />
            </div>
        )
    }

    return (
        <Layout>
            <Grid container justifyContent={"center"}  p={2} pt={10}>
                <Grid item xs={4}>
                    <Profile 
                        id={id}
                    />
                </Grid>
            </Grid>
        </Layout>
    )
}