import { useAPI } from "../../hooks"
import { Button } from "../Button"
import { Grid } from "../Grid"
import { Input } from "../Input"
import { Paper } from "../Paper"
import {useFormik} from 'formik'
import {Loader, LoaderContainer} from '../Loader'
import { UpdateProfileSchema } from "@balcao-de-leads/validations"
import { enqueueSnackbar } from "notistack"

export interface ProfileProps {
    id: string
}

export const Profile = ({id}: ProfileProps) => {
    const [user] = useAPI(`/user/${id}`)
    const [updatedUser, updateUser] = useAPI({url: `/user/${id}`, method: 'PUT'}, {
        manual: true
    })
    
    const formik = useFormik({
        initialValues: {
            password: '',
            password_confirmation: ''
        },
        validationSchema: UpdateProfileSchema,
        onSubmit: async (values, helpers) => {
            const {status} = await updateUser({
                data: values
            })

            if (status <= 299) {
                enqueueSnackbar('Perfil atualizado com sucesso!', {variant: 'success'})
                helpers.resetForm()
            }
        }
    })

    if (user.loading || updatedUser.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Paper>
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1} p={2}>
                    <Grid item xs={12}>
                        <Input 
                            label="Nome"
                            value={user.data?.name}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            label="Email"
                            value={user.data?.email}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            type="password"
                            name="password"
                            label="Senha"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            helperText={formik.touched.password && formik.errors.password}
                            onBlur={formik.handleBlur}
                            error={!!formik.touched.password && !!formik.errors.password}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            type="password"
                            name="password_confirmation"
                            label="Confirmação de Senha"
                            value={formik.values.password_confirmation}
                            onChange={formik.handleChange}
                            helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                            onBlur={formik.handleBlur}
                            error={!!formik.touched.password_confirmation && !!formik.errors.password_confirmation}
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="center" mt={2}>
                        <Button type="submit" disabled={!formik.dirty}>
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}