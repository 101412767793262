import { SignInSchema } from "@balcao-de-leads/validations"
import { useFormik } from "formik"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Grid, Input, Loader, Paper } from "../../components"
import { AuthContext } from "../../contexts"

export const Login = () => {
    const { signIn, isLoading } = useContext(AuthContext)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            password: '',
            email: ''
        },
        validationSchema: SignInSchema,
        onSubmit: async (values) => {
            await signIn(values)
            navigate('/home')
        }
    })

    if (isLoading) {
        return (
            <div style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Loader />
            </div>
        )
    }

    return (
        <Grid container justifyContent={"center"} height={"100vh"} alignItems="center">
            <Grid item xs={4}>
                <Paper>
                    <Grid container p={2} gap={2}>
                        <Grid item xs={12} textAlign="center">
                            <img src="android-chrome-192x192.png" height="100px" alt="LCM Logo"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Input 
                                label="Email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={!!formik.touched.email && !!formik.errors.email}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input 
                                label="Senha"
                                type="password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={!!formik.touched.password && !!formik.errors.password}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button onClick={formik.submitForm}>
                                Entrar
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}