import { Link } from "@mui/material"
import { Checkbox, Grid, Layout, Loader, Paper, Table } from "../../components"
import { useAPI } from "../../hooks"
import moment from 'moment'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { INTERESTS } from "../../utils";

export const Leads = () => {
    const [leads] = useAPI('/lead/all')
    
    if (leads.loading) {
        return (
            <div style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Loader />
            </div>
        )
    }

    return (
        <Layout>
            <Grid container justifyContent={"center"}  p={2} pt={10}>
                <Grid item xs={8} textAlign="right">
                    <Paper>
                        <Table 
                            columns={[
                                {
                                    field: 'pritority',
                                    type: 'boolean',
                                    headerName: 'Prioridade',
                                    width: 100,
                                    valueGetter: ({value}) => {
                                        return value === 0
                                    },
                                    renderCell: ({value}) => {
                                        return value ? (
                                            <PriorityHighIcon />
                                        ) : null
                                    }
                                },
                                {
                                    field: 'created_at',
                                    type: 'dateTime',
                                    headerName: 'Data',
                                    width: 180,
                                    valueGetter: ({row}) => new Date(row.created_at),
                                    valueFormatter: ({value}) => moment(value).format('DD/MM/YYYY HH:mm:ss')
                                },
                                {
                                    field: 'name',
                                    headerName: 'Nome',
                                    type: 'string',
                                    flex: 1
                                },
                                {
                                    field: 'phone_number',
                                    headerName: 'Telefone',
                                    type: 'string',
                                    flex: 1,
                                    renderCell: ({value}) => {
                                        return <Link href={`https://api.whatsapp.com/send?phone=${value}`} target={"_blank"}>{value}</Link>
                                    }
                                },
                                {
                                    field: 'email',
                                    headerName: 'Email',
                                    type: 'string',
                                    flex: 1
                                },
                                {
                                    field: 'interest',
                                    headerName: 'Curso',
                                    type: 'string',
                                    flex: 1,
                                    valueGetter: ({ value }) => {
                                        return INTERESTS.find(item => item.value === value)?.label
                                    }
                                },
                                {
                                    field: 'seller',
                                    headerName: 'Vendedor',
                                    type: 'string',
                                    flex: 1,
                                    valueGetter: ({ row }) => {
                                        return row?.lead_request_lead?.lead_request?.user?.name || ''
                                    }
                                },
                                {
                                    field: 'was_contacted_at',
                                    headerName: 'Contactado',
                                    type: 'boolean',
                                    valueGetter: ({row}) => {
                                        return Boolean(row?.lead_request_lead?.was_contacted_at)
                                    },
                                    renderCell: (params) => {
                                        return (
                                            <Checkbox 
                                                checked={params.value}
                                                disabled
                                            />
                                        )
                                    }
                                }
                            ]}
                            rows={leads.data || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}