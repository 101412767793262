import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useAPI, } from "../hooks";
import { Loader } from "../components";
import { SignInSchema } from "@balcao-de-leads/validations";
import { USER_ROLE } from "@balcao-de-leads/db";

export interface User {
    id: string
    name: string
    role: USER_ROLE
}
interface AuthContextValues {
    signed: boolean,
    isLoading: boolean,
    signIn: (values: typeof SignInSchema.__outputType) => void
    signOut: (callback: () => void) => void
    me: User | null
}

export const AuthContext = createContext({} as AuthContextValues);

export const AuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [token, setToken] = useState('')

    const [me, refetchMe] = useAPI('/auth/me')
    
    const [login, loginAPI] = useAPI({
        url: '/auth/signin',
        method: 'POST'
    }, {
        manual: true
    })
    
    const signIn = useCallback(async (values: typeof SignInSchema.__outputType) => {
        const { data } = await loginAPI({
            data: values
        })
        storeToken(data.token)
    }, [loginAPI])

    const signOut = useCallback((callback: () => void) => {
        window?.localStorage?.removeItem('@balcao-de-leads/token')

        setToken('')
        callback()
    }, [])

    const storeToken = (token: string) => {
        window?.localStorage?.setItem('@balcao-de-leads/token', token)

        setToken(token)
    }

    useEffect(() => {
        const storedToken = window.localStorage.getItem('@balcao-de-leads/token') || ''

        setToken(storedToken)
    }, [])

    useEffect(() => {
        if (token) {
            refetchMe()
        }
    }, [token])
    
    const isLoading = useMemo(() => {
        return login.loading || me.loading
    }, [login.loading, me.loading])

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                flex: '1',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Loader />
            </div>
        )
    }

 return (
   <AuthContext.Provider value={{ 
        signed: Boolean(me.data) && Boolean(token), 
        isLoading: isLoading,
        signIn,
        signOut,
        me: me.data
    }}>
     {children}
   </AuthContext.Provider>
 );
};