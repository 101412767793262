import { Link } from "@mui/material"
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { Button, Checkbox, Grid, Input, Layout, Loader, Paper, Table } from "../../components"
import { useAPI } from "../../hooks"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { enqueueSnackbar } from "notistack"
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ROLES } from "../../utils"
import { useNavigate } from "react-router-dom"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext } from "react"
import { AuthContext } from "../../contexts"

export const Users = () => {
    const {me} = useContext(AuthContext)
    const navigate = useNavigate()

    const [updatedUser, updateUser] = useAPI({url: `/user/`, method: 'PUT'}, {
        manual: true
    })

    const [users, refetchUsers] = useAPI('/user')

    if (updatedUser.loading || users.loading) {
        return (
            <div style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Loader />
            </div>
        )
    }

    return (
        <Layout>
            <Grid container justifyContent={"center"}  p={2} pt={10}>
                <Grid item xs={8} textAlign="right">
                    <Paper>
                        <Table 
                            rowHeight={65}
                            columns={[
                                {
                                    field: 'name',
                                    headerName: 'Nome',
                                    type: 'string',
                                    flex: 1
                                },
                                {
                                    field: 'email',
                                    headerName: 'Email',
                                    type: 'string',
                                    flex: 1,
                                },
                                {
                                    field: 'role',
                                    headerName: 'Cargo',
                                    type: 'singleSelect',
                                    flex: 1,
                                    valueOptions: ROLES,
                                    renderCell: (params) => {
                                        return (
                                            <Input 
                                                disabled={me?.id === params.id}
                                                select
                                                options={ROLES}
                                                value={params.value}
                                                onChange={async ({target: {value}}) => {
                                                    
                                                    const { status } = await updateUser({
                                                        url: `/user/${params.id}`,
                                                        data: {
                                                            role: value
                                                        }
                                                    }).then(refetchUsers)
                
                                                    if (status <= 299) {
                                                        enqueueSnackbar('Usuário atualizado com sucesso!', { variant: "success" })
                                                    }  
                                                }}
                                            />
                                        )
                                    }
                                },
                                {
                                    field: 'active',
                                    headerName: 'Ativo',
                                    type: 'boolean',
                                    renderCell: (params) => {
                                        return (
                                            <Checkbox 
                                                checked={params.value}
                                                disabled={me?.id === params.id}
                                                onChange={async () => {
                                                    const { status } = await updateUser({
                                                        url: `/user/${params.id}`,
                                                        data: {
                                                            active: !params.value
                                                        }
                                                    }).then(refetchUsers)
                
                                                    if (status <= 299) {
                                                        enqueueSnackbar('Usuário atualizado com sucesso!', { variant: "success" })
                                                    }   
                                                }}
                                            />
                                        )
                                    }
                                },
                                {
                                    field: 'actions',
                                    type: 'actions',
                                    getActions: (params: GridRowParams) => [
                                        <GridActionsCellItem icon={<MoreVertIcon />} onClick={() => navigate(`/usuarios/${params.id}`)} label="Ver Detalhes" />,
                                      ]
                                    
                                }
                            ]}
                            rows={users.data || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}