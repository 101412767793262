import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { AuthContext } from '../../contexts';
import { useNavigate } from 'react-router-dom';
import { USER_ROLE } from '@balcao-de-leads/db';

export const Layout: React.FC<React.PropsWithChildren> = ({children}) => {
  const {me, signOut} = React.useContext(AuthContext)
  const navigate = useNavigate()

  

  const pages = React.useMemo(() => {
    const pages = [
      {label: 'Home', onClick: () => {
        navigate('/home')
        handleCloseNavMenu()
      }}
    ];

    if (me?.role === USER_ROLE.ADMIN) {
      pages.push({
        label: 'Leads',
        onClick: () => {
          navigate('/leads')
          handleCloseNavMenu()
        }
      })

      pages.push({
        label: 'Usuários',
        onClick: () => {
          navigate('/usuarios')
          handleCloseNavMenu()
        }
      })
    }

    return pages
  }, [me?.role, navigate])

  const settings = [
    {
      label: 'Meu Perfil', 
      onClick: () => {
        navigate('/meu-perfil')
        handleCloseUserMenu()
    }},
    {
      label: 'Sair', 
      onClick: () => {
        signOut(() => navigate('/login'))
    }}
  ];

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <React.Fragment>
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src='/android-chrome-512x512.png' height={"50px"} alt="logo" />

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, ml: 5 }}>
            {pages.map((page) => (
              <Button
                key={page.label}
                onClick={page.onClick}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.label}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Menu">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.label} onClick={setting.onClick}>
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    <div
      style={{
        height: 'calc(100vh - 69px)',
        overflow: 'scroll'
      }}
    >
      {children}
    </div>
    </React.Fragment>
  );
}
