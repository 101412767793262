import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";
import { UnAuthRoute, AuthRoute } from "../components";
import { Home } from "./home";
import { Leads } from "./leads";
import { Login } from "./login";
import { ProfilePage } from "./profile";
import { Users } from "./users";
  
const router = createBrowserRouter([
    {
        path: "/",
        element: <UnAuthRoute><Navigate to="/login" /></UnAuthRoute>,
    },
    {
        path: "/login",
        element: <UnAuthRoute><Login /></UnAuthRoute>,
    },
    {
        path: "/home",
        element: <AuthRoute><Home /></AuthRoute>,
    },
    {
        path: "/meu-perfil",
        element: <AuthRoute><ProfilePage /></AuthRoute>,
    },
    {
        path: "/usuarios",
        element: <AuthRoute><Users /></AuthRoute>,
    },
    {
        path: "/usuarios/:id",
        element: <AuthRoute><ProfilePage /></AuthRoute>,
    },
    {
        path: "/leads",
        element: <AuthRoute><Leads /></AuthRoute>,
    },
]);

const Provider = () => {
    return (
        <RouterProvider router={router} />
    )
}

export default Provider