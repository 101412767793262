import { SnackbarProvider } from 'notistack';
import React from 'react';
import { ThemeProvider } from './components';
import { AuthContextProvider } from './contexts';
import RouterProvider from './pages'

function App() {
  return (
    <SnackbarProvider>
      <ThemeProvider>
        <AuthContextProvider>
          <RouterProvider />
        </AuthContextProvider>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
