import { INTEREST, USER_ROLE } from '@balcao-de-leads/db';

export const ROLES = [
    {value: USER_ROLE.ADMIN, label: 'Administrador'},
    {value: USER_ROLE.USER, label: 'Vendedor'},
]

export const INTERESTS = [
    {value: INTEREST.VCM, label: 'Viajando com Milhas'},
    {value: INTEREST.LCM_PRO_AGENCIA, label: 'Lucrando com Milhas (Agência)'},
    {value: INTEREST.LCM_PRO_BDM, label: 'Lucrando com Milhas (BDM)'},
]

