import { Link } from "@mui/material"
import { Button, Checkbox, Grid, Input, Layout, Loader, Modal, Paper, Table } from "../../components"
import { useAPI, useToggle } from "../../hooks"
import { enqueueSnackbar } from "notistack"
import moment from 'moment'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { INTERESTS } from "../../utils"
import { useFormik } from "formik"
import { useEffect } from "react"

export const Home = () => {
    const [modalVisibility, modalVisibilityControls]  = useToggle(false)
    const [leads, refetchLeads] = useAPI('/lead')
    const [requestedLeads, requestLeads] = useAPI({
        url: '/lead/request',
        method: 'POST'
    }, {
        manual: true
    })

    const [updatedLeadContact, updateLeadContact] = useAPI({
        method: 'PUT'
    }, {
        manual: true
    }) 

    const newLeadRequestForm = useFormik({
        initialValues: {
            interest: '',
        },
        onSubmit: async (values, helper) => {
            const {status} = await requestLeads({
                params: {
                    interest: values.interest
                }
            })
            
            if (status <= 299) {
                enqueueSnackbar('Novo lote gerado com sucesso!', {variant: 'success'})
                refetchLeads()
            }

            helper.resetForm()
            modalVisibilityControls.setFalse()
        }
    })

    useEffect(() => {
        if (!modalVisibility) {
            newLeadRequestForm.resetForm()
        }
    }, [modalVisibility])

    if (requestedLeads.loading || leads.loading) {
        return (
            <div style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Loader />
            </div>
        )
    }

    return (
        <Layout>
            <Grid container justifyContent={"center"}  p={2} pt={10}>
                <Grid item xs={8} textAlign="right">
                    <Button style={{marginBottom: 15}} onClick={modalVisibilityControls.setTrue}>
                        Gerar Novo Lote
                    </Button>
                    <Paper>
                        <Table 
                            columns={[
                                {
                                    field: 'pritority',
                                    type: 'boolean',
                                    headerName: 'Prioridade',
                                    width: 100,
                                    valueGetter: ({value}) => {
                                        return value === 0
                                    },
                                    renderCell: ({value}) => {
                                        return value ? (
                                            <PriorityHighIcon />
                                        ) : null
                                    }
                                },
                                {
                                    field: 'created_at',
                                    type: 'dateTime',
                                    headerName: 'Data',
                                    width: 180,
                                    valueGetter: ({row}) => new Date(row.created_at),
                                    valueFormatter: ({value}) => moment(value).format('DD/MM/YYYY HH:mm:ss')
                                },
                                {
                                    field: 'number',
                                    type: 'number',
                                    headerName: 'Nº do Lote',
                                    valueGetter: ({row}) => row.lead_request_lead.lead_request.number,
                                },
                                {
                                    field: 'name',
                                    headerName: 'Nome',
                                    type: 'string',
                                    flex: 1
                                },
                                {
                                    field: 'phone_number',
                                    headerName: 'Telefone',
                                    type: 'string',
                                    flex: 1,
                                    renderCell: ({value, row}) => {
                                        const firstName = row.name.split(' ')[0]

                                        const text = `Oi ${firstName}, tudo bem?`

                                        return <Link href={`https://wa.me/${value}?text=${encodeURI(text)}`} target={"_blank"}>{value}</Link>
                                    }
                                },
                                {
                                    field: 'interest',
                                    headerName: 'Curso',
                                    type: 'string',
                                    flex: 1,
                                    valueGetter: ({ value }) => {
                                        return INTERESTS.find(item => item.value === value)?.label
                                    }
                                },
                                {
                                    field: 'was_contacted_at',
                                    headerName: 'Contactado',
                                    type: 'boolean',
                                    valueGetter: ({row}) => {
                                        return Boolean(row.lead_request_lead.was_contacted_at)
                                    },
                                    renderCell: (params) => {
                                        return (
                                            <Checkbox 
                                                checked={params.value}
                                                onChange={async () => {
                                                    const { status } = await updateLeadContact({
                                                        url: `/lead/${params.id}/contact`,
                                                        data: {
                                                            was_contacted_at: params.row.lead_request_lead.was_contacted_at ? null : new Date()
                                                        }
                                                    }).then(refetchLeads)
                
                                                    if (status <= 299) {
                                                        enqueueSnackbar(!params.row.lead_request_lead.was_contacted_at ? 'Contato registrado com sucesso!' : 'Contato removido com sucesso!', {variant: "success"})
                                                    }   
                                                }}
                                            />
                                        )
                                    }
                                }
                            ]}
                            rows={leads.data || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
            <Modal
                title="Gerar Novo Lote"
                open={modalVisibility}
                onClose={modalVisibilityControls.setFalse}
                onConfirm={newLeadRequestForm.submitForm}
                confirmLabel="Gerar"
                bodyCustomCss={{
                    width: 400
                }}
            >
                <Input 
                    name="interest"
                    select
                    label="Produto"
                    options={INTERESTS}
                    onChange={newLeadRequestForm.handleChange}
                    onBlur={newLeadRequestForm.handleBlur}
                    value={newLeadRequestForm.values.interest}
                    helperText="Caso não deseje filtrar um produto, basta deixar este campo vazio."
                />
            </Modal>
        </Layout>
    )
}