"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateProfileSchema = exports.UpdateLeadContactSchema = exports.SignInSchema = void 0;
const Yup = __importStar(require("yup"));
const REQUIRED_FIELD = 'Este campo é obrigatório';
const MUST_BE_VALID_EMAIL = 'Este campo deve ser um email válido';
const MUST_BE_VALID_DATE = 'Este campo deve ser uma data válida';
exports.SignInSchema = Yup.object({
    password: Yup.string().required(REQUIRED_FIELD),
    email: Yup.string().email(MUST_BE_VALID_EMAIL).required(REQUIRED_FIELD),
});
exports.UpdateLeadContactSchema = Yup.object({
    was_contacted_at: Yup.date().nullable(),
});
exports.UpdateProfileSchema = Yup.object({
    role: Yup.string().oneOf(['ADMIN', 'USER']).optional(),
    active: Yup.boolean().optional(),
    password: Yup.string().test((value, schema) => {
        if (!value) {
            return true;
        }
        if ((value === null || value === void 0 ? void 0 : value.length) < 8) {
            return schema.createError({
                message: 'Deve conter no mínimo 8 caracteres'
            });
        }
        if (!(value === null || value === void 0 ? void 0 : value.match(/[a-z]+/))) {
            return schema.createError({
                message: 'Deve conter no mínimo uma letra minúscula'
            });
        }
        if (!(value === null || value === void 0 ? void 0 : value.match(/[A-Z]+/))) {
            return schema.createError({
                message: 'Deve conter no mínimo uma letra maiúscula'
            });
        }
        if (!(value === null || value === void 0 ? void 0 : value.match(/\d+/))) {
            return schema.createError({
                message: 'Deve conter um número'
            });
        }
        return true;
    }),
    password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'As senhas devem ser iguais').when('password', ([value], schema) => {
        if (!!value) {
            return schema.required(REQUIRED_FIELD);
        }
        return schema;
    }),
});
